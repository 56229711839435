import React from "react";

import styles from "./Hero.module.css";
import { getImageUrl } from "../../utils.js";

export const Hero = () => {
    return <section className={styles.container}>
        <div className={styles.content}>
            <h1 className={styles.title}>Hello,<br></br> I'm Hannah!</h1>
            <p className={styles.description}><span className={styles.impText}>PinPoint:</span> Bury, Greater Manchester! <br></br>I am a UX Developer with 8 years of industry experience with technologies including HTML5, CSS3/SASS/LESS, jQuery/JavaScript, Figma, Magento & recently React.</p>
            <a href="/CV-2023.pdf" className={styles.contactBtn} target="_blank" rel="noopener noreferrer">View CV</a>
        </div>
        <img src={getImageUrl("hero/heroImage2.png")} alt="Hero" className={styles.heroImg} />
    </section>;
};